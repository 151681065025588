export const KOKARDECZKA_SHOP_ID = 470
export const YOURSOUTLET_SHOP_ID = 571
export const PIANO_FASHION_SHOP_ID = 564
export const PRZEMO_SHOP_ID = 322
export const DESPERADO_SHOP_ID = 20
export const CHIQUE_SHOP_ID = 160
export const LAVITO_SHOP_ID = 224
export const MODNA_I_STYLOWA_SHOP_ID = 747
export const SZTOS_SHOP_ID = 477
export const TIM_SHOP_ID = 29
export const WIKA_EWA_FASHION_ID = 424
export const SENSI_SHOP_ID = 2361
export const CALZADO_SHOP_ID = 874
export const TEST_ACCOUNT_SHOP_ID = 5
export const LA_BELLA_SHOP_ID = 1767
export const SCRILU_SHOP_ID = 2028
export const CRISTAL_BIZU_SHOP_ID = 528
export const EMODNI_SHOP_ID = 23
export const SHE_SHOP_PATI = 534
export const SHOP_BAZAR_SHOP_ID = 201
export const SHOP_PRZYDA_CI_SIE = 1609
export const MERCERIA_SHOP_ID = 2072
export const EMILY_SHOP_ID = 1847
export const LALA_BOUTIQUE_SHOP_ID = 838
export const ANDRZEJ_GOLONKO_SHOP_ID = 2293
export const MAKEUP_BY_IWONA = 2647
export const MOI_BOUTIQUE_SHOP_ID = 2879
export const CUNHAS_SHOP_ID = 2967
export const SAMIPE_SHOP_ID = 3019
export const MMODA_SHOP_ID = 3386
export const NANA_SHOP_ID = 345
export const PEWEXOWY_SHOP_ID = 3054
export const MODA_AND_SZYK_SHOP_ID = 1873
export const SPLENDOR_ROOM_SHOP_ID = 4184
export const MODANOEMI_SHOP_ID = 3917
export const MODANOEMI_V2_SHOP_ID = 4867
export const TNK_SHOP_ID = 137
export const LES_BONBONS_DE_MANDY_SHOP_ID = 3125
export const OLI_ELEGANCJA = 2341;
export const SKLEP_WIELOBRANZOWY_KOWAL_SHOP_ID = 1041;
export const  PLAISIRS_SHOP_ID = 3520;
export const  LINA_SHOP_ID = 3772;
export const  PALAZZETTO_DELLE_ASTE = 4508;
export const  BUTIK_MADAME_SHOP_ID = 4557;
export const  BUTIK_MADAME_PLUS_SIZE_SHOP_ID = 1756;
export const  SASSI_SHOP_ID = 9411;
export const  KROLOWA_PLUS_SIZE_SHOP_ID = 9239;
export const  E_MODO_SHOP_ID = 357;
export const  NINA_MOLETKY_SHOP_ID = 2315;
export const ZAKUPOHOLICZKI_SHOP_ID = 4255;
export const OUI_OUI_FASHION_SHOP_ID = 5120;
export const SKLEP_ODZIEZOWY_U_EMI_SHOP_ID = 5173;
export const SWIAT_SLODYCZY_SHOP_ID = 1152;
export const ANGEL_GLADYS_SHOP_ID = 5092;
export const STYLOWE_BUTY_SHOP_ID = 450;
export const FIRANECZKI_U_OLECZKI_SHOP_ID = 749;
export const POPUPLIVE_STORE_SHOP_ID = 6369;
export const VANITY_SHOP_ID = 6326;
export const UNDERGROUND_LINE_SHOP_ID = 6760;
export const AILEMA_SHOP_ID = 263;
export const LE_DUPLEX_SHOP_ID = 2674;
export const LA_BOUTIQUE_DU_CENTRE_SHOP_ID = 2738;
export const STILL_MODA_STYL_SHOP_ID = 1375;
export const AUX_MEUBLES_JOLIS_SHOP_ID = 7983;
export const DRESS_WELL_SHOP_ID = 3550;
export const IMPERIO_DAS_SHOP_ID = 3454;
export const PINK_STORE_BY_LETICIA_SHOP_ID = 5314;
export const CELEBRE_SHOP_SHOP_ID = 9748;
export const CARMELA_SHOP_ID = 1411;
export const CARMELA2_SHOP_ID = 1412;
export const TOMI_STYLE_SHOP_ID = 1340;
export const INFINIK_SHOP_ID = 3336;
export const PATTI_CON_LE_SHOP_ID = 11002;
export const ANGELO_LILI_LIVE_SHOP_ID = 6329;
export const SKLEP_STELLA_SHOP_ID = 6942;
export const MARIA_BONITA_SHOP_ID = 5093;
export const MAGIC_ROSE_SHOP_ID = 4604;
export const LA_MODE_SHOP_ID = 440;
export const MODAS_MENKANTA_SHOP_ID = 12864;
export const PUPSIK_SHOP_ID = 775;
export const BY_ANN_SHOP_ID = 795;
export const MELU_SHOP_ID = 1623;
export const FASHION_DOROTA_SHOP_ID = 1270;
export const ESTORE_SHOP_ID = 1636;
export const COTE_MIROIR_SHOP_ID = 5479;
export const AMPARO_FASHION_SHOP_ID = 977;


import React, {useEffect, useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useSelector} from "react-redux";
import FacebookCircle from "../../../../../src_shared/components/ui/Iocns/FacebookCircle";
import {SHOP_ORIGIN_PL} from "../../enums/ShopOrigins";
import {getFromLS, saveToLS} from "../../../../../src_shared/helpers/LS";

const SelmoAppBar = () => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const selmoAppBarHidden = getFromLS('selmoAppBarHidden')
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        saveToLS('selmoAppBarHidden', hidden);
    }, [hidden])

    useEffect(() => {
        if (selmoAppBarHidden) {
            setHidden(true)
        }
    }, [selmoAppBarHidden])

    if (hidden) return null;

    return (
        <Link
            to={RouterPaths.SalesPostsHowItWorks}
            className="selmo-app-bar w-full relative overflow-hidden bg-gradient-to-l to-[#19AFFF] from-[#0062E0] flex-wrap flex text-[#fff] items-center font-semibold text-xs justify-center min-h-[40px] py-1.5 sm:py-1 px-[20px] sm:px-6 hover:text-[#fff] hover:no-underline group">
            <div
                style={{background: 'transparent', padding: 0}}
                class="main-container grow"
            >
                <div class="flex items-center justify-center lg:px-[225px]">
                    <span className="md:block hidden">{getLangText('new')}</span>
                    <FacebookCircle className="mx-2 md:block hidden" width={40} height={40} fill="#fff"/>
                    {getLangText('addPostOnFacebookBySelmoAndAnalyze')}
                    <div
                        className={`bg-[#fff] relative button white px-[8px] py-[3px] font-bold rounded-[5px] whitespace-nowrap ml-2 md:mr-0 mr-6 text-[#101827] group-hover:text-[#000] transition-all`}
                    >
                        {getLangText('findOutMoreButton')}
                    </div>
                </div>
                {lang === SHOP_ORIGIN_PL &&
                    <img
                        className="xl:block hidden absolute -top-[16px] xl:-top-[22px] right-0 h-[80px] xl:h-[100px] object-contain"
                        src="/assets/images/selmo-app-top-bar.webp"
                        alt=""
                    />
                }
            </div>
            <button
                type="button"
                className="ml-auto absolute right-4 top-1/2 -translate-y-1/2"
                onClick={() => setHidden(true)}
            >
                <i class="icon-cross"/>
            </button>
        </Link>
    );
};

export default SelmoAppBar;

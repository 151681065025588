import React, {useState} from 'react';
import Loader from "../../../shared/Loader";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import {services} from "../../../../RestServices";
import AssignToPlace from "../../../storage/scanner/assignToPlace/AssignToPlace";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";

const ShelfInfo = ({values, fetchData}) => {

    const {print, waiting: waitingForQRCode} = usePrintFile({
        rest: `${services.API_ORDER_QR_CODE}/${values.id}`,
    })
    const {getLangText} = useLang();

    const [showModal, setShowModal] = useState(false);

    return (
        <div className="summary-section mb-3">
            <div className="flex items-center justify-between">
                <div className="font-bold">
                    {getLangText('warehouseLocationLabel')}
                </div>
                <button
                    className={`button border-button small-size left-icon ${waitingForQRCode ? 'loading-button' : ''}`}
                    onClick={print}
                    type="button"
                >
                    {waitingForQRCode && <Loader isLoading/>}
                    <i className="icon-qr-code"/>
                    QR kod
                </button>
            </div>
            <div
                className={`rounded-[5px] inline-block p-2 text-xs mt-2 font-bold ${values.shelf_name ? 'bg-[#F9FAFB] border' : 'bg-[#FEF2F2] border border-[#F87171] text-[#981B1B]'}`}>
                {values.shippingData.shelf_name ?
                    values.shippingData.shelf_name :
                    getLangText('lackLabel')
                }
            </div>
            <div className="space-y-2 flex flex-col mt-2">
                <button
                    className="button border-button big-width"
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    {getLangText('assignPlace')}
                </button>
                <Link
                    to={`${RouterPaths.StorageScanner}/order/${values.id}?clickAction=1`}
                    className="button border-button big-width"
                    type="button"
                >
                    {getLangText('completeOrderLabel')}
                </Link>
                <Link
                    to={`${RouterPaths.StorageScanner}/order/${values.id}?clickAction=1&defaultView=2`}
                    className="button border-button big-width left-icon"
                    type="button"
                >
                    <i className="icon-qr-code" />
                    {getLangText('addProducts')}
                </Link>
            </div>
            {showModal &&
                <AssignToPlace
                    modalVisible={showModal}
                    hideModal={() => setShowModal(false)}
                    rest={services.API_WAREHOUSE_ORDER}
                    fetchData={() => fetchData(values.shop_order_id)}
                    orderId={values.id}
                />
            }
        </div>
    );
};

export default ShelfInfo;

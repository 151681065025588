import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";

const SelmoAppAd = ({closeMobileMenu}) => {
    const {getLangText} = useLang();
    
    return (
        <Link
            onClick={closeMobileMenu}
            to={RouterPaths.SalesPostsHowItWorks}
            className="mt-4 p-3 bg-selmo-app-aside-gradient rounded-[8px] relative overflow-hidden block hover:no-underline sm:border-4 sm:border-[#fff] group"
        >
            <img src="/assets/images/svg/dots-pattern.svg" className="pointer-events-none absolute top-0 left-0 w-full object-cover h-full" alt=""/>
            <div className="z-[1]">
                <div className="flex items-center text-xs font-bold text-[#191918]">
                    <img
                        className="w-[16px] mr-1"
                        src="/assets/images/svg/selmo-circle-blue.svg" alt=""/>
                    {getLangText('newInSelmo')}
                </div>
                <div
                    dangerouslySetInnerHTML={{__html: getLangText('addPostOnFacebookEasier')}}
                    className="text-xl font-bold text-[#101827] whitespace-pre-wrap text-with-gradient simple-text mt-4" />
                <img
                    className="w-[40px] ml-auto -rotate-[30deg] mt-2 mb-2 -mr-3"

                src="/assets/images/svg/facebook-logo-c.svg" alt=""/>
                <div className="text-xs font-bold text-[#E18722]">
                    {getLangText('findOutMoreButton')}
                    <i class="icon-arrow-left transition-all ml-[4px] group-hover:ml-2 text-[8px] mt-[1px] inline-block rotate-180" />
                </div>
            </div>
        </Link>
    );
};

export default SelmoAppAd;
import React from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import Loader from "../../app/shared/Loader";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import SettingsMessengerModel from "../../../modules/models/settings/SettingsMessengerModel";
import TextArea from "../../../src_shared/components/form/textarea/TextArea";
import SwitchField from "../../../src_shared/components/form/switchField/SwitchField";
import Field from "../../../src_shared/components/form/field/Field";
import {Helmet} from "react-helmet";
import useScrollToFoundSection from "../../../src_shared/hooks/useScrollToFoundSection";
import MultiRowListTemplates from "./components/MultiRowListTemplates";
import AddToCartRows from "./components/AddToCartRows";
import AddToReserveListRows from "./components/AddToReserveListRows";
import ProductHasVariantsRows from "./components/ProductHasVariantsRows";
import ProductWrongCodeRows from "./components/ProductWrongCodeRows";

const MessengerTemplates = () => {
    const {getLangText} = useLang();
    useScrollToFoundSection();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue,
        values,
        isLoading,
    }] = useFormLocal({
        rest: services.API_SETTINGS_MESSENGER_TEMPLATES,
        model: SettingsMessengerModel,
    });

    // if (isLoading || isWaiting) return <ComponentLoader/>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settings_nav_message_templates')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('settings_nav_message_templates')}</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page loader-parent">
                <Loader className="sticky-content" isLoading={isLoading || isWaiting}/>
                <div className="header-wrapper">
                    <h1>
                        <img src="/assets/images/settings/delivery.svg" alt="delivery"/>
                        {getLangText('settings_nav_message_templates')}
                    </h1>
                    <div className="description">{getLangText('settings_nav_message_templates_description')}</div>
                </div>
                <div className="settings-form">
                    <section id="comments">
                        <div className="left-side">
                            <h2 className="label">{getLangText('commentsLabel')}</h2>
                        </div>
                        <div className="form-col">
                            <SwitchField
                                {...getFieldProps('bot_comments_enabled')}
                                label={getLangText('automaticCommentReplyLabel')}
                                subLabel={getLangText('automaticCommentReplyText')}
                                className="switch-form inline-label align-items-start big-label light-version mt-0"
                            />
                            <SwitchField
                                {...getFieldProps('like_instead_of_comment')}
                                label={getLangText('likeInsteadOfCommentLabel')}
                                subLabel={getLangText('likeInsteadOfCommentText')}
                                className="switch-form inline-label align-items-start big-label light-version mt-0"
                            />
                            <SwitchField
                                {...getFieldProps('automatic_first_message')}
                                label={getLangText('automaticFirstMessageLabel')}
                                subLabel={getLangText('automaticFirstMessageText')}
                                className="switch-form inline-label align-items-start big-label light-version mt-0"
                            />
                            <SwitchField
                                {...getFieldProps('send_reserve_list_message')}
                                label={getLangText('privateMessageAboutReserveList')}
                                subLabel={getLangText('privateMessageAboutReserveListSubLabel')}
                                className="switch-form inline-label align-items-start big-label light-version mt-0"
                            />
                            <SwitchField
                                {...getFieldProps('message_instead_of_comment')}
                                label={getLangText('messageInsteadOfComment')}
                                subLabel={getLangText('messageInsteadOfCommentSubLabel')}
                                className="switch-form inline-label align-items-start big-label light-version mt-0"
                            />
                            <SwitchField
                                {...getFieldProps('comments_quantity_enabled')}
                                label={getLangText('commentsQuantityLabel')}
                                subLabel={getLangText('commentsQuantityText')}
                                className="switch-form inline-label align-items-start big-label light-version mt-0"
                            />
                            <Field
                                {...getFieldProps('comments_quantity_limit')}
                                label={getLangText('productsLimitInCodeLabel')}
                            />
                            <div className="d-flex w-100 mt-3">
                                <button
                                    type="submit"
                                    onClick={onSave}
                                    className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </section>
                    <section id="basket">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_messenger_cart')}</h2>
                        </div>
                        <div className="form-col">
                            <TextArea
                                {...getFieldProps('cart_text')}
                                label={getLangText('input_label_cart_status')}
                                rows={8}
                            />
                            <div className="form-info-box w-100 mb-3">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('input_label_cart_status')}</div>
                                    <div className="desc">
                                        <div className="mb-1">
                                            <div className="tag">{getLangText('input_tag_products')}</div>
                                            {getLangText('settings_messenger_tag_label_1')}
                                        </div>
                                        <div className="mb-1">
                                            <div className="tag">{getLangText('input_tag_price')}</div>
                                            {getLangText('settings_messenger_tag_label_2')}
                                        </div>
                                        <div>
                                            <div className="tag">{getLangText('number_tag')}</div>
                                            {getLangText('inThisPlaceWillAppearOrderNumber')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button-place">
                                <button
                                    type="submit"
                                    onClick={onSave}
                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </section>
                    <section id="summary">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_messenger_summary')}</h2>
                        </div>
                        <div className="form-col">
                            <TextArea
                                {...getFieldProps('summary')}
                                label={getLangText('settings_messenger_summary')}
                                rows={8}
                            />
                            <div className="form-info-box red mb-3">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div class="title">
                                        <strong>{getLangText('settings_messenger_summary_alert_1')}</strong>
                                    </div>
                                    <div class="desc">
                                        {getLangText('settings_messenger_summary_alert_2')}
                                        {' '}<strong>640</strong> - {getLangText('settings_messenger_summary_alert_3')}
                                    </div>
                                </div>
                            </div>
                            <div className="form-info-box w-100 mb-3">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('settings_messenger_info_data')}</div>
                                    <div className="desc">
                                        <div className="mb-1">
                                            <div className="tag">{getLangText('input_tag_products')}</div>
                                            {getLangText('settings_messenger_tag_label_1')}
                                        </div>
                                        <div className="mb-1">
                                            <div className="tag">{getLangText('input_tag_price')}</div>
                                            {getLangText('settings_messenger_tag_label_2')}
                                        </div>
                                        <div>
                                            <div className="tag">{getLangText('number_tag')}</div>
                                            {getLangText('inThisPlaceWillAppearOrderNumber')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button-place">
                                <button
                                    type="submit"
                                    onClick={onSave}
                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </section>
                    <section id="notifications">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_integrations_notification')}</h2>
                        </div>
                        <div className="form-col">
                            <TextArea
                                {...getFieldProps('notification_message')}
                                label={getLangText('settings_messenger_description_1')}
                                rows={8}
                            />
                            <div className="button-place">
                                <button
                                    type="submit"
                                    onClick={onSave}
                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </section>
                    <AddToCartRows values={values} />
                    <AddToReserveListRows values={values} />
                    <ProductHasVariantsRows values={values} />
                    <ProductWrongCodeRows values={values} />
                    <section id="inactive-product">
                        <div className="left-side">
                            <h2 className="label">{getLangText('inactiveProductMessageLabel')}</h2>
                        </div>
                        <div className="form-col">
                            <TextArea
                                {...getFieldProps('inactive_product_1')}
                                label={getLangText('inactiveProductMessageText')}
                                rows={8}
                            />
                            <div className="button-place">
                                <button
                                    type="submit"
                                    onClick={onSave}
                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </section>
                    <section id="limit-exceeded">
                        <div className="left-side">
                            <h2 className="label">{getLangText('limitExceededMessageLabel')}</h2>
                        </div>
                        <div className="form-col">
                            <TextArea
                                {...getFieldProps('limit_exceeded_1')}
                                label={getLangText('limitExceededMessageText')}
                                rows={8}
                            />
                            <div className="button-place">
                                <button
                                    type="submit"
                                    onClick={onSave}
                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default MessengerTemplates;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import QrScanner from "qr-scanner";
import Loader from "../../shared/Loader";
import {throttle} from "lodash";
import {useSelector} from "react-redux";
import {
    AMPARO_FASHION_SHOP_ID,
    BUTIK_MADAME_SHOP_ID,
    CHIQUE_SHOP_ID,
    COTE_MIROIR_SHOP_ID, PUPSIK_SHOP_ID, TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID,
    WIKA_EWA_FASHION_ID
} from "../../../../src_shared/enums/TrustedShopsIds";
import useLang from "../../../../src_shared/hooks/useLang";

const CustomScanner = ({handleResult, cameraId, waitingForScan, products, checkProducts, height, cameraFacingMode}) => {
    const videoEl = useRef(null);
    let scannerInstance = null
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const [isScannerLoading, setIsScannerLoading] = useState(false);
    // const singleScan = (+userData.id === WIKA_EWA_FASHION_ID || +userData.id === CHIQUE_SHOP_ID || +userData.id === TIM_SHOP_ID || +userData.id === COTE_MIROIR_SHOP_ID);
    // const singleScan = (+userData.id !== PUPSIK_SHOP_ID && +userData.id !== BUTIK_MADAME_SHOP_ID && +userData.id !== AMPARO_FASHION_SHOP_ID && +userData.id !== WIKA_EWA_FASHION_ID);
    const singleScan = false;
    const throttledHandleResult = useCallback((data) => {
                handleResult(data);
                scannerInstance.pause();
                setTimeout(() => {
                    scannerInstance.start()
                }, 500)
            }, [handleResult]
        )
        // throttle((data) => {
        //     handleResult(data);
        //     scannerInstance.destroy();
        //     setTimeout(() => {
        //         startCamera()
        //     }, 2000)
        // }, 2000),
        // [handleResult, products]
    ;

    const onScanSuccess = (result) => {
        if (singleScan) {
            handleResult(result.data);
            scannerInstance.destroy();
        } else {
            throttledHandleResult(result.data)
        }
    }

    const onScanFail = (err) => {
        console.log(err);
    };

    const startCamera = (delay = 1000) => {
        setIsScannerLoading(true);
        scannerInstance = new QrScanner(videoEl.current, onScanSuccess, {
            onDecodeError: onScanFail,
            returnDetailedScanResult: true,
            maxScansPerSecond: 1,
            highlightScanRegion: true,
            preferredCamera: cameraId || cameraFacingMode,
            // calculateScanRegion: (v) => {
            //     const smallestDimension = Math.min(v.videoWidth, v.videoHeight);
            //
            //     const scanRegionSize = Math.round(1 / 4 * smallestDimension);
            //
            //     let region: QrScanner.ScanRegion = {
            //         x: Math.round((v.videoWidth - scanRegionSize) / 2),
            //         y: Math.round((v.videoHeight - scanRegionSize) / 2),
            //         width: scanRegionSize,
            //         height: scanRegionSize,
            //     };
            //     return region;
            // }
        })

        setTimeout(() => {
            scannerInstance.start().then(() => setIsScannerLoading(false)).catch(console.error);
        }, delay)
    }

    useEffect(() => {
        if (!singleScan) {
            if (!videoEl.current || (checkProducts && !products.length)) return

            startCamera();

            // return () => {
            //     if (scannerInstance) {
            //         scannerInstance.destroy()
            //     }
            // }
        }
    }, [products, checkProducts, cameraFacingMode, singleScan])

    useEffect(() => {
        return () => {
            if (scannerInstance) {
                scannerInstance.destroy()
            }
        }
    }, [])

    return (
        <div
            style={{height: height}}
            className="qr-reader"
            onClick={() => startCamera(0)}
        >
            <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                {(!scannerInstance && singleScan && !waitingForScan) &&
                    <div className="font-bold text-sm">
                        {isScannerLoading ?
                            <div className="flex">
                                <Loader className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent"
                                        isLoading/>
                                {getLangText('loadingLabel')}
                            </div> :
                            getLangText('clickToScan')
                        }
                    </div>
                }
                <div
                    style={{
                        display: waitingForScan ? 'block' : 'none',
                        background: '#fff'
                    }}
                    className={`loader-container small-loader`}
                >
                    <div className="circle-loader">
                        <span className="circle"/>
                    </div>
                </div>
            </div>
            {/*{scannerInstance &&*/}
            <button
                type="button"
                onClick={() => scannerInstance?.toggleFlash()}
                className="absolute bottom-5 right-5 z-10 bg-[rgba(255,255,255,0.3)] backdrop-blur-sm flex justify-center items-center w-[35px] h-[35px] rounded-full">
                <img className="w-[30px]" src="/assets/images/flash-icon.png" alt=""/>
            </button>
            {/*}*/}
            <video ref={videoEl}></video>
        </div>
    );
};

export default CustomScanner;

import React, {useState} from 'react';
import {services} from "../../../../../../../RestServices";
import {useParams} from "react-router-dom";
import useFetchData from "../../../../../../../../src_shared/hooks/useFetchData";
import api from "../../../../../../../../services/axios/axios";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import Products from "./components/Products";
import OrderInfo from "../OrderInfo";
import Loader from "../../../../../../shared/Loader";
import QrScanner from "../../../../../../shared/components/QRScanner/QRScanner";
import Alert from "../completing/components/Alert";

const AssigningProducts = ({values, fetchData, isLoadingOrder}) => {
        const {id} = useParams();
        const {getLangText} = useLang();
        const [{isLoading, data: products, setData: setProducts, fetchData: fetchProducts}] = useFetchData({
            rest: `${services.API_WAREHOUSE_ORDER_PRODUCTS}/${id}`,
            initialData: [],
        });
        const [waitingForScan, setWaitingForScan] = useState(false);

        const [showAlert, setShowAlert] = useState({
            visible: false,
            data: {},
        })

        const scanProduct = async (text) => {
            try {
                JSON.parse(text);
                const product = JSON.parse(text);
                setWaitingForScan(true);

                try {
                    const {data} = await api.post(`/${services.API_WAREHOUSE_SHOP_PRODUCT_TO_ORDER}/${product.product_id}`, {
                        product_size_id: product.product_size_id,
                        order_id: id,
                    });

                    setShowAlert({
                        visible: true,
                        data: {
                            id: 'SUCCESS',
                            title: getLangText('yuhuLabel'),
                            desc: getLangText('productHasBeenAdded'),
                            icon: 'circle-check'
                        }
                    })
                    setProducts(data.products)

                } catch (e) {
                    alert(e?.response?.data?.message)
                    if (e?.response?.data?.message === 'PRODUCT_NOT_FOUND') {
                        setShowAlert({
                            visible: true,
                            data: {
                                id: 'FAIL',
                                title: getLangText('sthWentWrongWarning'),
                                desc: getLangText('productIsNotAssignedToOrderLabel'),
                                icon: 'circle-cross'
                            }
                        })
                    }
                } finally {
                    setWaitingForScan(false);
                }
            } catch (e) {

            } finally {
                setWaitingForScan(false);
            }
        };

        return (
            <>
                <div className="-mx-6 sticky top-0 bg-[white] z-[1]">
                    <div className="completing-scanner padd-border relative">
                        {showAlert.visible &&
                            <div
                                className="absolute top-0 left-0 right-0 mt-[44px] z-[10]"
                                style={{
                                    height: '40vh'
                                }}>
                                <Alert
                                    setShowAlert={setShowAlert}
                                    showAlert={showAlert}
                                />
                            </div>
                        }
                        <QrScanner
                            waitingForScan={waitingForScan}
                            height="40vh"
                            handleResult={scanProduct}
                            products={products}
                            checkProducts={true}
                        />
                    </div>
                </div>
                {isLoadingOrder ?
                    <div className="min-h-[130px] flex items-center justify-center">
                        <Loader isLoading={true} className="small-loader static-loader"/>
                    </div> :
                    <OrderInfo
                        fetchProducts={fetchProducts}
                        fetchData={fetchData}
                        values={values}
                    />
                }
                <div className="space-y-1 py-3">
                    {isLoading ?
                        <div className="animate-pulse space-y-1">
                            <div className="h-[54px] bg-gray-200 rounded-xl dark:bg-gray-700 w-full"></div>
                            <div className="h-[54px] bg-gray-200 rounded-xl dark:bg-gray-700 w-full"></div>
                        </div> :
                        <Products
                            fetchData={fetchProducts}
                            products={products}
                            currency={values?.currency?.text}
                            canEdit={false}
                        />
                    }
                </div>
            </>
        );
    }
;

export default AssigningProducts;

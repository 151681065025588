import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import SelmoPaczkaDataModal from "./components/dataForm/SelmoPaczkaDataModal";
import OrlenSelmoPaczka from "./components/orlen/OrlenSelmoPaczka";
import DpdSelmoPaczka from "./components/dpd/DpdSelmoPaczka";
import GeneratedLabel from "./components/shared/GeneratedLabel";
import SelmoPaczkaCreateShipping from "./components/dataForm/SelmoPaczkaCreateShipping";
import InpostSelmoPaczka from "./components/inpost/InpostSelmoPaczka";

const SelmoPaczka = ({data, fetchData}) => {
    const {getLangText} = useLang();
    const isQualified = data.sp_active;
    const [showDataFormModal, setShowDataFormModal] = useState(false);
    const [showCreateShippingModal, setShowCreateShippingModal] = useState(false);

    const BoxHeader = () => (
        <div className="flex items-center justify-between">
            <div>
                <div className="font-bold">
                    Selmo Paczka
                </div>
                <div className="text-[13px] font-medium text-desc">{getLangText('theLowestPricesForShipping')}</div>
            </div>
            <div className="ml-auto">
                <img className="w-[96px]" src="/assets/images/svg/selmo-paczka-logo.svg" alt="Selmo Paczka"/>
            </div>
        </div>
    )


    if (!data.shipping_address_exist) {
        return (
            <div className="summary-section mb-3 border-2 border-[#5FA5FA]">
                {BoxHeader()}
                <div
                    className="form-info-box align-items-center w-100 small-line-height mt-3">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                    </div>
                </div>
            </div>
        )
    }

    if (data?.sp_label_info?.label_type) {
        return (
            <div className="summary-section mb-3">
                {BoxHeader()}
                <GeneratedLabel
                    data={data}
                    fetchData={fetchData}
                />
            </div>
        )
    }

    if (!data?.sp_prices?.dpd?.length && !data?.sp_prices?.dpd_pickup?.length && !data?.sp_prices?.orlen_paczka?.length && !data?.sp_prices?.inpost_box?.length) return null;

    return (
        <div className="summary-section mb-3 border-2 border-[#5FA5FA]">
            {BoxHeader()}
            {(!!data?.sp_prices?.dpd?.length || !!data?.sp_prices?.dpd_pickup?.length) &&
                <DpdSelmoPaczka
                    data={data}
                    fetchData={fetchData}
                    options={!!data?.sp_prices?.dpd?.length ? data?.sp_prices?.dpd : data?.sp_prices?.dpd_pickup}
                    isCourier={!!data?.sp_prices?.dpd?.length}
                    showDataModal={() => setShowDataFormModal(true)}
                    isQualified={isQualified}
                />
            }
            {(!!data?.sp_prices?.inpost_box?.length || !!data?.sp_prices?.inpost?.length) &&
                <InpostSelmoPaczka
                    data={data}
                    fetchData={fetchData}
                    options={!!data?.sp_prices?.inpost?.length ? data?.sp_prices?.inpost : data?.sp_prices?.inpost_box}
                    isCourier={!!data?.sp_prices?.inpost?.length}
                    showDataModal={() => setShowDataFormModal(true)}
                    isQualified={isQualified}
                />
            }
            {!!data?.sp_prices?.orlen_paczka?.length &&
                <OrlenSelmoPaczka
                    data={data}
                    fetchData={fetchData}
                    options={data?.sp_prices?.orlen_paczka || []}
                    showDataModal={() => setShowDataFormModal(true)}
                    isQualified={isQualified}
                />
            }
            {showDataFormModal &&
                <SelmoPaczkaDataModal
                    fetchData={fetchData}
                    showModal={showDataFormModal}
                    setShowCreateShippingModal={setShowCreateShippingModal}
                    hide={() => setShowDataFormModal(false)}
                />
            }
            {showCreateShippingModal &&
                <SelmoPaczkaCreateShipping
                    showModal={showCreateShippingModal}
                    hide={() => setShowCreateShippingModal(false)}
                    fetchData={fetchData}
                />
            }
        </div>
    );
};

export default SelmoPaczka;

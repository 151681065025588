import React, {useEffect, useState} from 'react';
import {services} from "../../RestServices";
import {Link, useHistory, useParams} from "react-router-dom";
import useLang from "../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../routes/RouterPath";
import {saveToLS} from "../../../src_shared/helpers/LS";
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import Model from "../../../src_shared/modules/model/Model";
import Loader from "../../../src_shared/components/loader/Loader";
import useFetchData from "../../../src_shared/hooks/useFetchData";

const ShopifyIntegration = () => {

    const params = useParams();
    const {getLangText} = useLang();
    const history = useHistory();

    const [{isLoading, isError}] = useFetchData({
        rest: `${services.API_SHOPIFY_PUBLIC_CONNECTION_HANDLE}/${params.hash}`,
        onError: (error) => {
            history.push(RouterPaths.Auth);
        }
    })

    const [{onSave, isWaiting}] = useFormLocal({
        model: new Model(),
        rest: `${services.API_SHOPIFY_PUBLIC_CONNECTION_HANDLE}/${params.hash}`,
        initialAutoLoad: false,
        afterSubmit: (response) => {
            window.location.href = response.shopifyRedirect
        },
    });

    useEffect(() => {
        if (params.hash) {
            saveToLS('Shopify-Hash', params.hash)
        }
    }, [params.hash]);

    return (
        <div className="h-full">
            <div
                className="loader-parent"
                style={{
                    height: '100%',
                    background: '#fff',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                }}
            >
                <div className="max-w-[480px] mx-auto px-6">
                    <div className="flex items-center justify-center mb-4">
                        <img className="" alt="selmo" width={100} src="/assets/images/selmo-logo.svg"/>
                        <div className="text-desc text-[8px] mx-3"><i class="icon-cross"/></div>
                        <img style={{width: 'auto', height: '30px'}} src="/assets/images/svg/shopify.svg"
                             alt="Shopify"/>
                    </div>
                    {isLoading ?
                        <div>
                            <div
                                style={{display: 'block', height: 'auto'}}
                                className={`loader-container static-loader`}
                            >
                                <div className="circle-loader">
                                    <span className="circle"/>
                                </div>
                            </div>
                            <div className="font-bold text-[#000] text-center">{getLangText('loadingLabel')}</div>
                        </div> :
                        isError ?
                            <div className="font-medium">
                                {getLangText('sthWentWrongWarning')}
                            </div> :
                            <div className="font-medium text-center">
                                <div>Connect Shopify with your Selmo</div>
                                <div>In connecting your Selmo account, you agree to share basic account information
                                    related to your use to Shopify.
                                </div>
                                {/*<div>You are signed in as <strong></strong></div>*/}
                                <button
                                    onClick={onSave}
                                    type="button"
                                    className={`button primary mt-4 black w-full ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting && <Loader isLoading/>}
                                    Connect my Shopify account
                                </button>
                                <Link
                                    className="text-center hover:no-underline hover:text-[#000] transition-all text-xs text-desc mt-3 mx-auto block"
                                    to={RouterPaths.Auth}>
                                    Cancel
                                </Link>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ShopifyIntegration;

import React, {useState} from 'react';
import Loader from "../../../../../messenger/loader/Loader";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../../src_shared/modules/model/Model";
import BalanceModal from "./BalanceModal";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import {batch, useDispatch} from "react-redux";
import SessionActions from "../../../../../shared/session/SessionActions";

const PickupPointSelected = ({
                                 options = [],
                                 orderId,
                                 rest,
                                 fetchData,
                                 selectedPointName = '',
                                 imgSrc = '/assets/images/svg/orlen-paczka.svg',
                                 imgWidth = 40,
                                 selectedPointLabel = 'selectedPoint',
                                 showDataModal,
                                 isQualified,
                                 type,
                             }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [showBalanceModal, setShowBalanceModal] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const itemsToShow = showAll ? options : options.slice(0, 3);

    const afterSubmit = (response) => {
        fetchData()
        batch(() => {
            dispatch(showAlert('labelHasBennGeneratedLabel', ''))
            dispatch(SessionActions.updateUserData('sp_balance', response))
        })
    }

    const onError = (e) => {
        if (e?.response?.data?.message === 'NOT_ENOUGH_BALANCE') {
            setShowBalanceModal(true);
        } else {
            dispatch(showAlert(e?.response?.data?.message, '', 'alert-fail'))
        }
    }

    const [{onSave, isWaiting, setValue, values}] = useFormLocal({
        model: new Model(),
        rest: `${rest}/${orderId}`,
        initialAutoLoad: false,
        afterSubmit,
        onError
    });

    return (
        <div className="mt-3">
            {!!selectedPointName &&
                <div className="flex items-center p-2 bg-[#F9FAFB] rounded-[5px]">
                    <img
                        width={imgWidth}
                        className="min-w-[40px] mr-2"
                        src={imgSrc}
                        style={{width: imgWidth, minWidth: imgWidth}}
                        alt=""
                    />
                    <div className="text-xs">
                        <div className="font-bold">{getLangText(selectedPointLabel)}</div>
                        <div>{selectedPointName}</div>
                    </div>
                </div>
            }
            <div className="mt-3">
                <div className="font-bold text-sm mb-2">{getLangText('selectSize')}</div>
                <div className="data-boxes-wrapper separated extra-small-padd">
                    {itemsToShow.map((item) => (
                        <div
                            className="animate-[fadeIn_.3s_ease_forwards]"
                            key={item.id}>
                            <div className="content payment-content">
                                <div className="content__top-part">
                                    <input
                                        type="radio"
                                        name="price"
                                        checked={values.price === item.id}
                                        onChange={() => setValue('price', item.id)}
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1 flex items-center">
                                        <div className="font-bold">
                                            {item.description}
                                        </div>
                                        <div className="ml-auto font-medium text-desc">
                                            {item.price} zł
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {options.length > 3 && (
                        <button
                            type="button"
                            className="button border-button w-full small-size mb-2"
                            onClick={() => setShowAll(!showAll)}>
                            {showAll ? 'Pokaż mniej' : 'Pokaż wszystkie gabaryty'}
                        </button>
                    )}
                    {type === 'orlen' &&
                        <div
                            className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">
                                    Paczkę należy zanieść do automatu
                                </div>
                            </div>
                        </div>
                    }
                    {type?.includes('inpost') &&
                        <div
                            className="form-info-box align-items-start w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">
                                    Zlecenie odbioru paczki jest tworzone codziennie o godzinie 14:00. Upewnij się, że
                                    przed wygenerowaniem etykiety paczka jest gotowa do odbioru przez kuriera.
                                </div>
                            </div>
                        </div>
                    }
                    {type === 'dpd' &&
                        <div
                            className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">
                                    Kurier przyjedzie w następnym dniu roboczym
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="tooltip-parent">
                    {!values.price &&
                        <div className="tooltip-content">
                            {getLangText('selectSize')}
                        </div>
                    }
                    <button
                        disabled={!values.price}
                        onClick={(isQualified === null || !isQualified) ? showDataModal : onSave}
                        type="button"
                        className={`button primary w-full mt-2 ${!values.price ? 'not-active' : 'selmo-bg'} ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader/>}
                        {getLangText('createLabelLabel')}
                    </button>
                </div>
            </div>
            {showBalanceModal &&
                <BalanceModal
                    hideModal={() => setShowBalanceModal(false)}
                    show={showBalanceModal}
                    extraValues={{
                        order_id: orderId,
                        price: values.price,
                        amount: options.find((option) => option.id === values.price)?.price
                    }}
                />
            }
        </div>
    );
};

export default PickupPointSelected;

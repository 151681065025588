import React from 'react';
import PickupPointSelected from "../shared/PickupPointSelected";
import {services} from "../../../../../../RestServices";

const InpostSelmoPaczka = ({data, options, fetchData, showDataModal, isQualified, isCourier}) => {
    const selectedPointName = data.pointMachineCode ? `${data.pointMachineStreet}, ${data.pointMachineCity}` : null;

    const getSelectedPointData = () => {
        if (isCourier) {
            return {
                selectedPointName: 'Inpost Kurier',
                imgSrc: '/assets/images/inpost.svg',
                imgWidth: 40,
                selectedPointLabel: 'selectedCourier'
            }
        }
        return {
            selectedPointName: selectedPointName,
            imgSrc: '/assets/images/inpost.svg',
            imgWidth: 40,
            selectedPointLabel: 'selectedPoint'
        }
    }

    return (
        <PickupPointSelected
            {...getSelectedPointData()}
            rest={services.API_PACKAGE_INPOST_LABEL}
            orderId={data.id}
            fetchData={fetchData}
            options={options}
            showDataModal={showDataModal}
            isQualified={isQualified}
            type={isCourier ? 'inpost' : 'inpost_box'}
        />
    );
};

export default InpostSelmoPaczka;
